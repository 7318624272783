<template>
  <v-container class="not-found">
    <v-row align="center" style="height: 100vh">
      <v-col cols="6">
        <v-col>
          <div class="pa">
            <v-icon color="error">help</v-icon>
            <span class="subtitle subtitle-error">Erro 404</span>
          </div>
          <h3 class="big-number">Não achou o que buscava?</h3>
          <p class="subtitle">O conteúdo que você está buscando não existe.</p>
        </v-col>
      </v-col>
      <v-col cols="6">
        <img :src="require('@/assets/not-found.png')" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFound',
}
</script>

<style lang="scss">
.subtitle-error {
  font-size: 16px !important;
  color: $vermelho-l20 !important;
}
.big-number {
  text-transform: inherit !important;
  color: $marca-0-l40 !important;
}
</style>
